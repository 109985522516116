@import '../../sass/theme'
.render
  +container

  h1
    +large-white-text

  .noResultsContainer
    height: auto
    width: 100%
    background-color: rgba(255, 255, 255, 0.08)
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin-bottom: 200px
    padding: 56px 0

    &:before
      background-size: contain
      left: 0

    h2
      +small-medium-white-text
    p
      +small-grey-text
      margin-bottom: 30px

  h3
    +small-medium-white-text
    text-align: center

  p
    +small-white-text
    font-size: 14px
    text-align: center
    margin-bottom: 15px

