@import 'src/sass/theme'

.container
  +container
  align-items: flex-start

.balanceManagement
  display: flex
  align-items: flex-start
  justify-content: space-between
  width: 100%

  .balanceContent
    display: flex
    flex-direction: column
    width: 100%
    min-width: 65%

    .cardRow
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 40px

    .contentHeading
      +medium-white-text

