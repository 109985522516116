@import 'src/sass/theme'

.container

  .infoContainer::-webkit-scrollbar
    width: 10px
  .infoContainer::-webkit-scrollbar-track
    background: #f1f1f100
  .infoContainer::-webkit-scrollbar-thumb
    background: #999
  .infoContainer::-webkit-scrollbar-thumb:hover
    background: #ffffff

  .infoContainer
    width: 400px
    border: 1px solid rgba(255, 255, 255, 0.16)
    margin-left: 100px
    margin-top: 100px
    padding: 24px
    overflow-x: scroll

    .section
      margin-bottom: 24px

      h3
        width: auto
        margin: 0 0 0 0
        +small-medium-white-text
      .link
        margin: 0 0 0 0
        +small-grey-text

      .sectionRow
        display: flex
        justify-content: space-between
        align-items: center

        .name
          +small-grey-text
          margin: 0 0 8px 0
        .value
          +small-white-text
          margin: 0 0 8px 0
      .graphic
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.16) 1px, transparent 1px), linear-gradient(to bottom, rgba(255, 255, 255, 0.16) 1px, transparent 1px)
        background-size: 15px 10px
        height: 32px


      .time
        height: 100%
        display: flex
        justify-content: space-around
        align-items: flex-end

      .time div 
        // color: white
        font-size: 12px
        width: 25%
        height: 100%
        margin: 1px 2px
        display: flex
        flex-direction: column 
        align-items: center
        justify-content: end
        position: relative

      .time div p
        position: absolute
        margin-top: -25px

      .time div:first-child p
        color: #EFA2C5
      .time div:nth-child(2) p 
        color: #69B99C
      .time div:nth-child(3) p 
        color: #FCF1F1
      .time div:nth-child(4) p 
        color: #AD90E9
        
      .time div hr
        width: 100%
        align-self: bottom
        margin: 1px 2px
        height: 3px
        border: 2px
        border-radius: 1px 1px 1px 1px

      .line1
        background-color: #EFA2C5
      .line2
        background-color: #69B99C
      .line3
        background-color: #FCF1F1
      .line4
        background-color: #AD90E9
