@import 'src/sass/theme'

.container
  .infoWrapper
    display: flex
    align-items: center

    span
      +small-white-text
      margin-right: 5px
      margin-bottom: 5px

  .slider
    width: 100%
    height: 1px
    // background: rgba(255, 255, 255, 0.24)
    -webkit-appearance: none
    margin: 20px 0

    &::-webkit-slider-thumb
      -webkit-appearance: none
      appearance: none
      width: 27px
      height: 27px
      border: 0
      background: url('../../../static/svg/rangeSliderIcon.svg')
      cursor: pointer
      background-color: rgb(49,54,83)
      border-radius: 50%

    &::-webkit-slider-runnable-track
      -webkit-appearance: none
      color: $white

.percentileContainer
  width: 100%
  height: 14px
  position: relative
  color: white
  display: flex
  div
    position: absolute
    margin-top: -4px
// transform-origin: center