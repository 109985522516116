@import 'src/sass/theme'

.buttonWrapper
  display: flex
  width: 100%
  align-items: center
  justify-content: space-between

  button
    width: 49%

.select
  height: 36px
  margin-bottom: 20px
  background-color: transparent
  border: 1px solid rgba(255, 255, 255, 0.16)
  color: rgba(255, 255, 255, 0.72)
  padding: 0 65px 0 10px
  outline: none
  width: 100%

.label
  +small-white-text
  display: flex
  align-self: flex-start
  margin-bottom: 5px

select option
  background-color: rgb(110, 110, 110)
  color: white

select option:hover 
  background-color: black