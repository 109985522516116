@import 'src/sass/theme'
.socials
  display: flex
  align-items: center
  justify-content: space-between
  gap: 32px
  +phone
    justify-content: space-between
    width: 70%
    margin: 16px 0 16px 25px

  img
    cursor: pointer
