@import 'src/sass/theme'

.container
  label
    display: block
    position: relative
    padding-left: 35px
    margin-bottom: 12px
    cursor: pointer
    font-size: 22px
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    +small-white-text
    margin-top: 15px

    input
      position: absolute
      opacity: 0
      cursor: pointer
      height: 0
      width: 0

      &:checked ~ .checkmark
        background-color: transparent

        &:after
          display: block

    .checkmark
      position: absolute
      top: 0
      left: 0
      height: 25px
      width: 25px
      background-color: transparent
      border: 1px solid rgba(255, 255, 255, 0.48)

      &:after
        content: ""
        position: absolute
        display: none
        left: 9px
        top: 5px
        width: 5px
        height: 10px
        border: solid white
        border-width: 0 3px 3px 0
        -webkit-transform: rotate(45deg)
        -ms-transform: rotate(45deg)
        transform: rotate(45deg)

  p
    +small-grey-text


