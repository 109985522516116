@import '../../sass/theme'

.breadcrumbs
  margin: 0 0 30px 0
  overflow: hidden
  width: 100%
  padding: 0
  display: flex
  flex-direction: column

  .linkWrapper
    display: flex

    .activeClass
      color: $white
    .inactiveClass
      color: rgba(255, 255, 255, 0.48)
    .passedClass
      color: #4AB44A
      &:before
        background: rgba(74, 180, 74, 0.16)
      &:after
        background: #4AB44A

  li
    list-style-type: none
    color: white
    text-transform: uppercase
    font-size: 14px
    width: 25%
    height: 80px
    position: relative
    text-align: center

    p
      position: absolute
      top: 5px
      left: 47%
      margin: 0

  li:before
    content: ''
    width: 28px
    height: 28px
    line-height: 28px
    display: flex
    font-size: 18px
    color: $white
    background: rgba(255, 255, 255, 0.16)
    margin-left: 25px
    border-radius: 20px

  li:after
    content: ''
    width: 3px
    height: 52px
    background: rgba(255, 255, 255, 0.16)
    position: absolute
    top: -65%
    color: $white
  p
    margin: 5px
