@import 'src/sass/theme'
@import 'src/sass/fonts'

.headingContainer
  display: flex
  p
    color: $white
    padding: 4px 0 0 8px
    font-size: 14px
    font-family: $faktum

.formHeading
  +medium-white-text
