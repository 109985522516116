@import 'fonts'
@import 'variables'

@mixin small-grey-text
  font-family: $pt-root-ui
  font-weight: 500
  font-size: 14px
  line-height: 20px
  color: $white
  opacity: 0.4
  margin-bottom: 0
  margin-top: 0

@mixin medium-grey-text
  font-family: $pt-root-ui
  font-weight: 500
  font-size: 16px
  line-height: 22px
  color: $white
  opacity: 0.4
  margin-bottom: 0
  margin-top: 0

@mixin small-white-text
  font-family: $pt-root-ui
  font-weight: 500
  font-size: 16px
  line-height: 22px
  color: $white
  margin: 0

@mixin small-medium-white-text
  font-family: $faktum
  font-weight: 600
  font-size: 18px
  line-height: 24px
  color: $white

@mixin medium-white-text
  font-family: $faktum
  font-weight: 500
  font-size: 24px
  line-height: 28px
  color: $white

@mixin large-white-text
  font-family: $faktum
  font-weight: 600
  font-size: 44px
  line-height: 48px
  color: $white
