@import 'src/sass/theme'

.container
  .selectWrapper
    display: flex
    align-items: center

    select
      background: transparent
      height: 36px
      margin-top: 7px
      border-left: none
      border-color: rgba(255, 255, 255, 0.16)
      color: $white
      outline: none
    input
      border-right: none
    option
      background-color: rgb(110, 110, 110)
    
    option:hover
      background-color: red
      color: red

  label
    color: $light-blue
    font-size: 14px
    font-weight: 700
    &:hover
      cursor: pointer
