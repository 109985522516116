@import 'src/sass/theme'

.modalWrapper
  display: flex
  align-items: center
  justify-content: center
  z-index: 3
  background-color: rgba(0, 0, 0, 0.5)
  position: fixed
  left: 0
  top: 0
  width: 100vw
  height: 100vh
  overflow-y: hidden

.modal
  display: flex
  flex-direction: column
  align-items: center
  padding: 20px
  background-color: #252834
  max-width: 540px
  width: 100%

  .modalHeading
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    margin-bottom: 20px
    +medium-white-text

    img
      cursor: pointer

    p
      margin: 0

.modalWrapperClosed
  display: none