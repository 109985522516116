@import 'src/sass/theme'

.imageButton
    border: none
    display: inline-block
    background-color: transparent

.inputIconOne
    position: absolute
    right: 10px
    top: 42%

    &:hover
      cursor: pointer

.inputIconTwo
    position: absolute
    right: 40px
    top: 45%

    &:hover
      cursor: pointer
