@import 'src/sass/theme'

.header
  display: flex
  justify-content: space-between
  align-items: center
  height: 64px
  width: 100%
  background-color: $bg
  padding: 20px
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.16)

  .hamburgerIcon
    +phone-and-tablet
      display: flex
      &:hover
        cursor: pointer
    +tablet
      display: none
    +desktop
      display: none
    +hd-desktop
      display: none


