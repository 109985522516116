@import 'src/sass/theme'

.navbar
  display: flex
  align-items: center
  justify-content: space-between
  gap: 32px
  +phone
    display: none
  +phone-and-tablet
    width: 70%
  +tablet-portrait
    width: 60%
  +tablet
    width: 50%

  .navLink
    +medium-grey-text
    white-space: nowrap
    text-decoration: none

    &:hover
      cursor: pointer

.active
  +small-white-text
  text-decoration: none

.walletInfoWrapper
  display: flex
  align-items: center
  margin-left: 20px

  .walletBalanceCol
    display: flex
    flex-direction: column
    justify-content: center
    margin-left: 15px

  .address
    +small-white-text
    font-size: 14px
  .balance
    +small-grey-text
    font-size: 14px

  .logout
    height: 40px
    width: 40px
    margin-left: 15px

    &:hover
      cursor: pointer
