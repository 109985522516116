@import 'src/sass/theme'
.container
  +layout

  .balanceHeading
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    margin: 40px 0

    .daoNameWrapper
      display: flex
      align-items: center

      .nameWrapper
        display: flex
        flex-direction: column
        align-items: flex-start
        margin-left: 10px

        h3
          margin: 0
          +medium-white-text

        p
          +small-grey-text

    .rightSideWrapper
      display: flex
      align-items: center

      .createProposalLink
        text-decoration: none
      p
        +small-grey-text
        margin: 0 10px

.balanceGrid
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-template-rows: repeat(4, 1fr)
  grid-column-gap: 8px
  grid-row-gap: 8px
  margin-bottom: 100px

  .bic1
    grid-area: 1 / 1 / 2 / 2
  .bic2
    grid-area: 1 / 2 / 2 / 3
  .bic3
    grid-area: 1 / 3 / 2 / 4
  .bic4
    grid-area: 2 / 1 / 3 / 2
  .bic5s
    grid-area: 2 / 2 / 3 / 3
  .bic6
    grid-area: 2 / 3 / 3 / 4
  .bic7
    grid-area: 3 / 1 / 5 / 4
    padding: 16px 24px
    background-color: rgba(255, 255, 255, 0.08)
    font-weight: 400
    overflow-x: auto
    max-height: 165px
    text-align: justify
    +small-white-text
  .bic8
    grid-area: 1 / 4 / 5 / 6
    background-image: url('../../static/svg/bg-balance.svg')
    background-size: cover
    background-repeat: no-repeat
    padding: 16px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between

    .infoWrapper
      width: 100%
      .yourBalance
        +small-grey-text
      .balanceValue
        margin: 0
        +medium-white-text
      .voting
        +small-white-text

    button
      width: 100%

.tableSectionHeading
  display: flex
  align-items: center
  justify-content: space-between

  p
    +medium-white-text

  .rightContentWrapper
    display: flex
    align-items: center
    justify-content: flex-end
    width: 50%

    div
      flex-direction: row
    input
      margin-bottom: 0
      width: 100%
    button
      margin-left: 8px
