@import 'src/sass/theme.sass'
.container
  .footer
    width: 100%
    display: flex
    flex-direction: column
    background: rgba(0, 0, 0, 0.16)
    +phone
      display: none
    +phone-and-tablet
      display: none
    +tablet-portrait
      display: none

  .topFooter
    max-width: 1160px
    width: 100%
    display: flex
    justify-content: space-between
    padding-bottom: 30px
    padding-top: 30px
    margin: 0 auto
    background-color: transparent
    +phone-and-tablet
      flex-direction: column

  .logoButtonsWrapper
    display: flex
    align-items: center
    justify-content: space-between
    padding-right: 80px
    +phone
      flex-direction: column
      align-items: flex-start
      padding-right: 0

  .topFooterLinksWrapper
    display: flex
    justify-content: space-between
    width: 60%
    align-items: flex-start
    +phone-and-tablet
      flex-direction: row
      margin-left: 40px
      margin-top: 45px

  .topFooterLinks
    display: flex
    flex-direction: column
    align-items: flex-start

  .footerLogo
    +tablet
      margin-left: 40px
    +phone
      margin-bottom: 20px
      margin-left: 20px

  .heading
    +small-white-text
    margin-bottom: 10px
    font-weight: 700

  .buttonWrapper
    display: flex
    flex-direction: column
    align-items: center

    +phone-and-tablet
      flex-direction: row
    +phone
      flex-direction: column
      width: 100%
      padding-right: 20px
      padding-left: 20px

    a
      +phone
        width: 100%

    button
      width: 184px
      margin-bottom: 18px
      +tablet
        margin-left: 10px
        margin-bottom: 0
      +phone
        width: 100%

  .bottomFooter
    width: 100%
    height: 80px
    max-width: 1200px
    margin: 0 auto
    display: flex
    align-items: center
    justify-content: space-between
    background-color: transparent
    border-top: 0.5px solid rgba(255, 255, 255, 0.16)
    +phone
      flex-direction: column-reverse
      align-items: flex-start
      justify-content: space-around
      height: inherit
      padding-top: 16px
      padding-bottom: 16px

  .links
    display: flex
    align-items: center
    justify-content: space-between
    gap: 32px
    &:hover
      cursor: pointer
    +phone
      width: 80%
      justify-content: space-between
      margin-left: 25px
      margin-bottom: 20px

  .copy
    color: $white
    margin: 0
    text-decoration: none
    +phone
      margin-left: 25px

  .footerLink
    text-decoration: none
    +small-grey-text
    font-weight: 400
    font-size: 14px
    margin: 7px 0
    white-space: nowrap

    &:hover
      color: $white
      opacity: 1

  .mobileFooter
    display: flex
    flex-direction: column
    background: rgba(0, 0, 0, 0.16)
    +desktop
      display: none
    +hd-desktop
      display: none


  .navLink
    +medium-grey-text
    white-space: nowrap
    text-decoration: none
    font-weight: 400
    font-size: 14px
    margin: 5px 0

    &:hover
      cursor: pointer

.active
  +small-white-text
  text-decoration: none
  font-weight: 400
  font-size: 14px
  margin: 4px 0
