@import '../../sass/theme'

.container
  +layout

  .daoSettings
    display: flex

    .contentWrapper
      display: flex
      flex-direction: column
      max-width: 376px
      width: 100%

      label
        +small-white-text
        margin-bottom: 5px

      p
        +small-medium-white-text

    textarea
      +small-white-text
      height: 94px
      background-color: transparent
      border: 1px solid rgba(255, 255, 255, 0.16)
      padding: 10px
      color: rgba(255, 255, 255, 0.72)
      margin-bottom: 30px

    button
      width: fit-content

    .line
      margin: 20px 0
      width: 100%
      border-bottom: 1px solid rgba(255, 255, 255, 0.16)

