@import 'src/sass/theme'

.container
  .createDaoContent
    display: flex
    flex-direction: column
    align-items: center

  .createDao
    display: flex

  .formNavigation
    width: 100%
    display: flex
    align-items: center
    background: rgba(255, 255, 255, 0.08)
    position: sticky
    bottom: 0
    padding-left: 376px

    button
      margin-right: 164px
