@import 'src/sass/theme'

.container
  +container
  padding-bottom: 100px


  .contentWrapper
    +container
    display: flex
    align-items: center
    flex-direction: row
    width: 100%
    height: 500px
    margin-bottom: 20px

    .wrapper
      width: 100%
      background-color: rgba(255, 255, 255, 0.08)
      display: flex
      flex-direction: column
      align-items: flex-start
      padding: 36px
      height: 100%

      .line
        width: 100%
        border-bottom: 1px solid rgba(255, 255, 255, 0.08)

      h3
        +small-medium-white-text
        font-size: 16px

      label
        +small-white-text
        margin-bottom: 5px

      textarea
        +small-white-text
        background: transparent
        width: 100%
        outline: none
        padding: 5px 10px

      .action
        display: flex
        flex-direction: column
        width: 100%
        height: 48px

        .actionRow
          display: flex
          justify-content: space-between
          width: 100%

          .actionName
            +small-white-text
            font-size: 14px
            margin: 10px 0

      button
        background-color: rgba(197, 228, 243, 0.16)
        color: $light-blue
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        margin-top: 10px
        border: none
        outline: none

        &:hover
          cursor: pointer

        img
          margin-right: 10px


    .left
      margin-right: 8px
    .right
      margin-left: 8px

  .bottomLine
    +container
    width: 100%
    border-bottom: 1px solid rgba(255, 255, 255, 0.08)

  .buttonWrapper
    margin-top: 20px
    display: flex
    justify-content: flex-end
    width: 100%

.textarea
  resize: vertical
