@import 'src/sass/theme'

.isLoggedIn
  +container

  a
    text-decoration: none

  button
    width: 134px
    font-weight: 700
    margin-left: 10px
    padding: 0
    +phone
      margin-left: 0
      margin-right: 10px
  h1
    +large-white-text
    margin: 64px 0 44px 0
    +tablet
      text-align: center

  .buttonWrapper
    display: flex

  .daoCardsWrapper
    display: flex
    flex: 1
    justify-content: space-between
    flex-wrap: wrap
    width: 100%
    margin-bottom: 150px
