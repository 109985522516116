@import './responsive'
@mixin layout
  max-width: 1360px
  margin: 0 auto
  background-color: $bg
  position: relative

@mixin container
  width: 100%
  max-width: 1160px
  margin: 0 auto
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  background-color: $bg
  +tablet
    padding-left: 10px
    padding-right: 10px
  +phone-and-tablet
    padding-left: 40px
    padding-right: 40px
  +phone
    padding-left: 16px
    padding-right: 16px
