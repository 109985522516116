@import 'src/sass/theme'

.amount
  +small-white-text

.inputWrapper
  position: relative
  display: flex
  flex-direction: column
  align-items: flex-start

  input
    margin-bottom: 5px !important

  button
    position: absolute
    right: 5px
    top: 10px
    cursor: pointer
    height: 25px
    color: $light-blue
    padding: 12px

.result
  +small-grey-text
  margin-bottom: 20px
