@import 'src/sass/theme'
.container
  max-width: 376px
  width: 100%

  label
    +small-white-text

.textarea
  +small-white-text
  height: 94px
  background-color: transparent
  border: 1px solid rgba(255, 255, 255, 0.16)
  margin-bottom: 30px
  width: 100%
  padding: 10px
  color: rgba(255, 255, 255, 0.72)
  resize: vertical
