@import 'src/sass/theme'

.yourAccountCard
  width: 49%
  height: 76px
  display: flex
  flex-direction: column
  justify-content: flex-start
  padding: 16px 24px
  background: rgba(255, 255, 255, 0.08)

  .cardText
    +small-grey-text

  .tokenRow
    display: flex
    align-items: center

    .token
      +small-medium-white-text
      margin: 5px 0 5px 5px
      font-size: 20px