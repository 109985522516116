@font-face
  font-family: 'faktum'
  font-weight: 500
  src: url('/static/fonts/Faktum-Regular.otf') format('otf')

@font-face
  font-family: 'faktum'
  font-weight: 600
  src: url('/static/fonts/Faktum-Bold.otf') format('otf')

@font-face
  font-family: 'pt-root-ui'
  font-weight: 500
  src: url('/static/fonts/PT Root UI_Regular.otf') format('otf')

@font-face
  font-family: 'pt-root-ui'
  font-weight: 700
  src: url('/static/fonts/PT Root UI_Bold.otf') format('otf')

$faktum: Faktum, 'faktum', Avenir, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif
$pt-root-ui: PT Root UI, 'pt-root-ui', Avenir, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif
