@import 'src/sass/theme'

.tabsWrapper
  display: flex
  align-items: center
  cursor: pointer
  margin: 16px 0

  .tab
    width: 100%
    text-align: center
    height: 35px
    +small-grey-text

  .active
    width: 100%
    height: 35px
    border-bottom: 1px solid $white
    text-align: center
    +small-white-text
    font-size: 14px
