.tableWrapper
  border-collapse: collapse
  border: none
  display: flex
  flex-direction: column
  width: 100%
  background-color: rgba(255, 255, 255, 0.08)
  margin-bottom: 50px
  padding: 25px 16px 16px 16px
  overflow-x: auto
