@import 'src/sass/theme'

.inputWrapper
  display: flex
  flex-direction: column
  width: 100%
  position: relative

  .labelWrapper
    flex-direction: row
    display: flex
    justify-content: space-between
    align-items: center

    .label
      +small-white-text
      margin-bottom: 5px

    .labelIcon
      margin-left: 5px
  // margin-bottom: 5px

  .labelWrapper2
    flex-direction: row
    display: flex
    justify-content: space-between
    align-items: center

  .label2
    +small-white-text

  .labelIcon2
    margin-left: 5px

  .input
    height: 36px
    margin-bottom: 20px
    background-color: transparent
    border: 1px solid rgba(255, 255, 255, 0.16)
    color: rgba(255, 255, 255, 0.72)
    // padding: 0 65px 0 10px
    outline: none

  .inputIconOne
    position: absolute
    right: 10px
    top: 42%

    &:hover
      cursor: pointer

  .inputIconTwo
    position: absolute
    right: 40px
    top: 45%

    &:hover
      cursor: pointer

  spaning
    color: red
    font-size: 14px
    margin-top: -0.8rem
    margin-bottom: 0.8rem

.labelInnerWrap
  display: flex
  justify-content: center
  align-items: center
  color: grey
  font-size: 14px

.labelInnerWrap2
  display: flex
  justify-content: center
  align-items: center
  color: #C5E4F3
  font-size: 14px
  margin-bottom: 5px
  text-decoration: none
  a
    margin-bottom: -4px
    text-decoration: none
  div
    margin-right: 8px
    color: #C5E4F3
    cursor: pointer
    text-decoration: none