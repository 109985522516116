@import 'src/sass/theme'

.routeBreadcrumbs
  display: flex
  align-items: center
  margin-top: 20px
  width: 100%

  .active
    +small-white-text

  .inactive
    +small-grey-text

  .arrow
    margin: 0 10px