@import 'src/sass/theme'
.daoCardLink
  max-width: 572px
  min-width: 372px
  width: 100%
.daoCard
  display: flex
  flex-direction: column
  justify-content: space-between
  background: rgba(255, 255, 255, 0.08)
  height: 160px
  width: 100%
  padding: 20px
  margin-bottom: 20px

  .daoCardTopRow
    display: flex
    justify-content: space-between
    align-items: flex-start

  .daoInfoWrapper
    display: flex
    align-items: flex-start

  .daoInfoColumn
    margin-left: 10px

    h3
      margin: 0
      +small-medium-white-text

    .description
      +small-grey-text
      display: block
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap
      width: 400px


  .settings
    display: flex

    p
      margin: 0 0 0 10px
      +small-grey-text

      &:hover
        cursor: pointer

  .daoCardBottomRow
    display: flex
    align-items: center

    img
      margin-left: 5px
      margin-right: 10px
      cursor: pointer

    .link
      +small-grey-text
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap
      cursor: pointer

    .buyDomain
      font-weight: 500
      color: $light-blue
      margin-left: auto
      white-space: nowrap
      cursor: pointer

